import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Link, useParams } from "react-router-dom";
import TrackingDetails from "./TrackingDetails";
import fetchTrackingDetails from "../../redux/thunk/fetchTrackingDetails";
import { Container, Image, Button } from "react-bootstrap";
import usps from "../../assets/USPS.png";
import Loading from "../ui/Loading";
import Error from "../ui/Error";

export default function TrackingView() {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const trackAPIDetails = useSelector((state) => state.trackAPIDetails);
  const {
    loading,
    isError,
    success,
    error,
    trackingAPI: allTrackingAPI,
  } = trackAPIDetails || {};
  console.log(allTrackingAPI);

  useEffect(() => {
    dispatch(fetchTrackingDetails(orderId));
  }, [dispatch, orderId]);

  useEffect(() => {
    if (allTrackingAPI?.length > 0) {
      setCurrentIndex(0);
    }
  }, [allTrackingAPI]);

  const handleNext = () => {
    if (currentIndex < allTrackingAPI.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  return (
    <Container>
      <h2 className="text-center">Live Shipment Tracking Details</h2>
      <Link to="https://www.usps.com/">
        <Image src={usps} alt="USPS" style={{ width: "300px" }} />
      </Link>
      <p>
        <i>
          [This tool displays all shipping updates scanned by USPS using MID
          technology. Please do not panic if your shipments estimated delivery
          displays a later date than expected OR does not seem to be making
          progress. USPS expedites live parcels bypassing the scanning process
          often causing incorrect estimated delivery to display. This is
          industry wide and not specific to Valley Hatchery.]
        </i>
      </p>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && <Loading />}
        {allTrackingAPI?.length >= 0 && isError && <Error message={error} />}
      </div>
      {!loading && allTrackingAPI?.length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TrackingDetails
              key={uuidv4()}
              trackingAPI={allTrackingAPI[currentIndex]}
              currentIndex={currentIndex + 1}
              totalIndex={allTrackingAPI?.length}
              loading={loading}
              isError={isError}
              success={success}
            />
            {allTrackingAPI.length === 1 ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "200px",
                  marginTop: "20px",
                }}
              >
                <Button onClick={handlePrevious} disabled={currentIndex === 0}>
                  Previous
                </Button>
                <Button
                  onClick={handleNext}
                  disabled={currentIndex === allTrackingAPI.length - 1}
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </Container>
  );
}
