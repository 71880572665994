import { deleteTrackOrderList } from "../trackAPI/actions";
import {
  DELETE_LIST_TRACKING_ORDER_FAIL,
  DELETE_LIST_TRACKING_ORDER_REQUEST,
} from "../trackAPI/actionType";
import axios from "./utils/axios";

const fetchDeleteTrackList = (selectedRowId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: DELETE_LIST_TRACKING_ORDER_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.delete("api/tracking/delete/", {
        ...config,
        data: { track_data_list: selectedRowId },
      });
      dispatch(deleteTrackOrderList(data));
    } catch (error) {
      dispatch({
        type: DELETE_LIST_TRACKING_ORDER_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
};
export default fetchDeleteTrackList;
