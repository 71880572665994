import React from "react";

import { Col, Row } from "react-bootstrap";
export default function SingleRow({ tracking }) {
  const { status, date: datetime, location } = tracking || {};
  console.log(location, "Location ");
  const date = new Date(datetime);
  date.setHours(date.getHours() - 4);
  // date.setMinutes(date.getMinutes() - 10);
  // date.setHours(date.getHours() + 4);
  const options = {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const formatter = new Intl.DateTimeFormat("en-US", options);
  const formattedDate = formatter.format(date);

  let locationSet = "";
  if (location === "None" || location === "    " || location === " ") {
    locationSet = "";
  } else {
    locationSet = (
      <>
        <small>Location : {location}</small>
        <br />
      </>
    );
  }

  return (
    <Row style={{ border: "1px solid black", padding: "5px 10px" }}>
      <Col
        sm={6}
        md={4}
        lg={4}
        xl={4}
        style={{ borderRight: "1px solid black", textAlign: "center" }}
      >
        {formattedDate}
      </Col>
      <Col
        sm={6}
        md={8}
        lg={8}
        xl={8}
        style={{
          textAlign: "center",
          textTransform: "uppercase",
          fontWeight: "bold",
        }}
      >
        {locationSet}
        {status}
      </Col>
    </Row>
  );
}
