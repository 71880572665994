export const ADD_BLACKLIST_WORD_REQUEST =
  "blacklistAPI/ADD_BLACKLIST_WORD_REQUEST";
export const ADD_BLACKLIST_WORD_SUCCESS =
  "blacklistAPI/ADD_BLACKLIST_WORD_SUCCESS";
export const ADD_BLACKLIST_WORD_FAIL = "blacklistAPI/ADD_BLACKLIST_WORD_FAIL";

export const LIST_BLACKLIST_WORD_REQUEST =
  "blacklistAPI/LIST_BLACKLIST_WORD_REQUEST";
export const LIST_BLACKLIST_WORD_SUCCESS =
  "blacklistAPI/LIST_BLACKLIST_WORD_SUCCESS";
export const LIST_BLACKLIST_WORD_FAIL = "blacklistAPI/LIST_BLACKLIST_WORD_FAIL";

export const UPDATE_BLACKLIST_WORD_REQUEST =
  "blacklistAPI/UPDATE_BLACKLIST_WORD_REQUEST";
export const UPDATE_BLACKLIST_WORD_SUCCESS =
  "blacklistAPI/UPDATE_BLACKLIST_WORD_SUCCESS";
export const UPDATE_BLACKLIST_WORD_FAIL =
  "blacklistAPI/UPDATE_BLACKLIST_WORD_FAIL";

export const DELETE_BLACKLIST_WORD_REQUEST =
  "blacklistAPI/DELETE_BLACKLIST_WORD_REQUEST";
export const DELETE_BLACKLIST_WORD_SUCCESS =
  "blacklistAPI/DELETE_BLACKLIST_WORD_SUCCESS";
export const DELETE_BLACKLIST_WORD_FAIL =
  "blacklistAPI/DELETE_BLACKLIST_WORD_FAIL";
