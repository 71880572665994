import React, { useEffect, useState } from "react";

import Result from "../Result";
import SuccessReload from "../ui/SuccessReload";

export default function TrackingDetails({
  loading,
  isError,
  success,
  trackingAPI,
  totalIndex,
  currentIndex,
}) {
  const [estimateDateStart, setEstimateDateStart] = useState("");
  const [estimateDateEnd, setEstimateDateEnd] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [msg, setMsg] = useState(null);
  console.log(currentIndex);
  console.log(totalIndex, "this");

  useEffect(() => {
    if (trackingAPI && trackingAPI.status && trackingAPI.status.length > 0) {
      try {
        let first_level_date =
          trackingAPI.status[trackingAPI.status.length - 1].date;
        setDeliveryStatus(trackingAPI.status[0].status);
        const orginalestimateDate = new Date(first_level_date);
        orginalestimateDate.setDate(orginalestimateDate.getDate() + 1);
        setEstimateDateStart(orginalestimateDate.toISOString());
        orginalestimateDate.setDate(orginalestimateDate.getDate() + 2);
        setEstimateDateEnd(orginalestimateDate.toISOString());
      } catch (error) {
        console.error(error);
        // Handle the error as per your application's requirements
      }
    }
  }, [trackingAPI]);

  const date_start = new Date(estimateDateStart);
  const date_end = new Date(estimateDateEnd);

  const options = {
    month: "long",
    day: "numeric",
  };

  const estimateDeliveryDateStart = date_start.toLocaleString("en-US", options);
  const estimateDeliveryDateEnd = date_end.toLocaleString("en-US", options);

  useEffect(() => {
    if (deliveryStatus) {
      if (!deliveryStatus.includes("Delivered")) {
        setMsg(
          <p style={{ fontSize: "24px" }}>
            {estimateDeliveryDateStart} - {estimateDeliveryDateEnd}
          </p>
        );
      } else {
        setMsg(<p style={{ fontSize: "24px" }}>Delivered</p>);
      }
    } else {
      setMsg(<p style={{ fontSize: "24px" }}>Loading ....</p>);
    }
  }, [deliveryStatus, estimateDeliveryDateStart, estimateDeliveryDateEnd]);

  let content = "No Data Found";
  if (!loading && !isError && success && trackingAPI.status.length === 0)
    content = (
      <SuccessReload message="Loading Data - Please Click Here to Refresh Tracking Information" />
    );
  if (!loading && !isError && success && trackingAPI.status.length > 0) {
    content = <Result trackingAPI={trackingAPI.status} />;
  }

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ fontWeight: "bold", fontSize: "24px", marginRight: "5px" }}>
          Estimated Delivery:{" "}
        </p>
        {msg}
      </div>
      {totalIndex > 1 && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ fontSize: "18px" }}>
            Shipment {currentIndex} of {totalIndex}
          </p>
        </div>
      )}

      <br />
      {content}
    </>
  );
}
