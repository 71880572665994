
export default function SuccessReload({ message }) {
    const reloadPage = () => {
      window.location.reload();
    };
    return (
      <div style={{ color: "#1168db" }}>
        {message} <button onClick={reloadPage}>Reload</button>
      </div>
    );
  }
  