export const TRACKING_API_REQUEST = "trackAPI/TRACKING_API_REQUEST";
export const TRACKING_API_SUCCESS = "trackAPI/TRACKING_API_SUCCESS";
export const TRACKING_API_FAIL = "trackAPI/TRACKING_API_FAIL";

export const TRACKING_API_DETAILS_REQUEST =
  "trackAPI/TRACKING_API_DETAILS_REQUEST";
export const TRACKING_API_DETAILS_SUCCESS =
  "trackAPI/TRACKING_API_DETAILS_SUCCESS";
export const TRACKING_API_DETAILS_FAIL = "trackAPI/TRACKING_API_DETAILS_FAIL";

export const ADD_TRACKING_ORDER_REQUEST = "trackAPI/ADD_TRACKING_ORDER_REQUEST";
export const ADD_TRACKING_ORDER_SUCCESS = "trackAPI/ADD_TRACKING_ORDER_SUCCESS";
export const ADD_TRACKING_ORDER_FAIL = "trackAPI/ADD_TRACKING_ORDER_FAIL";

export const VIEW_TRACKING_ORDER_REQUEST =
  "trackAPI/VIEW_TRACKING_ORDER_REQUEST";
export const VIEW_TRACKING_ORDER_SUCCESS =
  "trackAPI/VIEW_TRACKING_ORDER_SUCCESS";
export const VIEW_TRACKING_ORDER_FAIL = "trackAPI/VIEW_TRACKING_ORDER_FAIL";

export const UPDATE_TRACKING_ORDER_REQUEST =
  "trackAPI/UPDATE_TRACKING_ORDER_REQUEST";
export const UPDATE_TRACKING_ORDER_SUCCESS =
  "trackAPI/UPDATE_TRACKING_ORDER_SUCCESS";
export const UPDATE_TRACKING_ORDER_FAIL = "trackAPI/UPDATE_TRACKING_ORDER_FAIL";

export const DELETE_TRACKING_ORDER_REQUEST =
  "trackAPI/DELETE_TRACKING_ORDER_REQUEST";
export const DELETE_TRACKING_ORDER_SUCCESS =
  "trackAPI/DELETE_TRACKING_ORDER_SUCCESS";
export const DELETE_TRACKING_ORDER_FAIL = "trackAPI/DELETE_TRACKING_ORDER_FAIL";

export const DELETE_LIST_TRACKING_ORDER_REQUEST =
  "trackAPI/DELETE_LIST_TRACKING_ORDER_REQUEST";
export const DELETE_LIST_TRACKING_ORDER_SUCCESS =
  "trackAPI/DELETE_LIST_TRACKING_ORDER_SUCCESS";
export const DELETE_LIST_TRACKING_ORDER_FAIL =
  "trackAPI/DELETE_LIST_TRACKING_ORDER_FAIL";
