import React, { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import fetchViewTrack from "../../redux/thunk/fetchViewTrackOrder";
import TrackSingleTable from "../TrackSingleTable";
import Error from "../ui/Error";
import Loading from "../ui/Loading";
import Success from "../ui/Success";
import fetchDeleteTrackList from "../../redux/thunk/fetchDeleteList";
import axios from "../../redux/thunk/utils/axios";

export default function TrackList() {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const trackOrderView = useSelector((state) => state.trackOrderView);
  const trackOrderAdd = useSelector((state) => state.trackOrderAdd);
  const trackOrderListDelete = useSelector(
    (state) => state.trackOrderListDelete
  );
  const { userInfo } = login || {};
  let user_type = false;

  if (userInfo !== undefined) {
    user_type = userInfo.isAdmin;
  }
  const [deleteId, setDeleteId] = useState(0);
  const [trackOrderId, setTrackOrderId] = useState("");
  const [successFileUpload, setSuccessFileUpload] = useState(false);
  const { success: trackAddSuccess } = trackOrderAdd || {};
  const { success: trackListDeleteSuccess } = trackOrderListDelete || [];
  const [selectedFile, setSelectedFile] = useState(null);
  const { loading, trackOrder, success, isError } = trackOrderView || {};

  const [selectedRowId, setSelectedRowId] = useState([]);
  const selectrowcheck = (rowId) => {
    setSelectedRowId((prevSelectedRowId) =>
      prevSelectedRowId.includes(rowId)
        ? prevSelectedRowId.filter((id) => id !== rowId)
        : [...prevSelectedRowId, rowId]
    );
  };
  const deleteMultiple = () => {
    if (user_type === true) dispatch(fetchDeleteTrackList(selectedRowId));
  };
  const selectall = () => {
    if (selectedRowId.length === 0) {
      const allRowIds = trackOrder?.map((d) => d._id) || [];
      setSelectedRowId(allRowIds);
    } else {
      setSelectedRowId([]);
    }
  };

  useEffect(() => {
    dispatch(fetchViewTrack);
  }, [dispatch, trackAddSuccess, trackListDeleteSuccess]);

  const handleChildClick = (id) => {
    setDeleteId(id);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const fileUploadHandler = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.post("api/track_csv_upload/", formData, config);
      document.getElementById("file-input").value = "";
      setSelectedFile(null);
      setSuccessFileUpload(true);
    } catch (error) {
      setSelectedFile(null);
      document.getElementById("file-input").value = "";
    }
  };

  let content = null;
  if (loading) content = <Loading />;
  if (!loading && isError)
    content = <Error message="There is an error in this table" />;
  if (!loading && !isError && success && trackOrder?.length === 0)
    content = <Success message="Data Not Found" />;
  if (!loading && !isError && success && trackOrder?.length > 0) {
    const trackOrderFilter = trackOrder?.filter(
      (item) =>
        item.tracking_number
          .toLowerCase()
          .includes(trackOrderId.toLowerCase()) ||
        item.order_number.toLowerCase().includes(trackOrderId.toLowerCase())
    );
    const trackOrders = trackOrderFilter?.filter(
      (item) => item._id !== deleteId
    );
    content = trackOrders?.map((trackOrderSingle, index) => (
      <TrackSingleTable
        key={trackOrderSingle?._id}
        trackOrderSingle={trackOrderSingle}
        index={index}
        onChildClick={handleChildClick}
        selectrow={selectrowcheck}
        isSelected={selectedRowId.includes(trackOrderSingle?._id)}
      />
    ));
  }

  return (
    <div>
      <h1>Track List</h1>
      <hr />
      <br />
      <Form style={{ marginBottom: "40px" }}>
        <Form.Group
          className="mx-4"
          style={{ justifyContent: "space-between", display: "flex" }}
        >
          <Form.Control
            type="search"
            style={{ width: "500px", height: "55px", borderRadius: "12px" }}
            placeholder="Search Tracking ID Or Order ID"
            value={trackOrderId}
            onChange={(e) => setTrackOrderId(e.target.value)}
            aria-label="Search"
          ></Form.Control>
          {user_type && (
            <div>
              <input
                type="file"
                id="file-input"
                style={{ width: "210px", height: "40px", fontSize: "16px" }}
                onChange={handleFileChange}
              />
              <Button
                type="button"
                className="btn btn-info"
                onClick={fileUploadHandler}
              >
                Upload Track Excel{" "}
              </Button>
            </div>
          )}
        </Form.Group>
        {successFileUpload && (
          <p
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: " 25px",
              color: "#0D6DDD",
            }}
          >
            Tracking Data Upload Successfully
          </p>
        )}
      </Form>
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>
              {user_type && (
                <>
                  <input
                    type="checkbox"
                    name="checkbox"
                    onChange={() => selectall()}
                  />{" "}
                  <Button variant="danger" onClick={() => deleteMultiple()}>
                    Delete
                  </Button>
                </>
              )}
            </th>
            <th>ID</th>
            <th>Tracking ID</th>
            <th>OrderID</th>
            <th>#</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>{content}</tbody>
      </Table>
    </div>
  );
}
