export default function Footer() {
  return (
    <footer
      className="pt-6"
      style={{ textAlign: "center", margin: "60px 0px 10px" }}
    >
      <div className="max-w-7xl mx-auto px-5 py-6 lg:px-0 flex justify-between gap-2 border-t text-sm text-slate-400">
        <div>Copyright 2024 VALLEY HATCHERY.</div>
      </div>
    </footer>
  );
}
